import { gql, useQuery } from '@apollo/client';
import {
  Button,
  Center,
  Divider,
  Input,
  Loader,
  Skeleton,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import type { UseFormReturnType } from '@mantine/form/lib/types';
import { useModals } from '@mantine/modals';
import { isNil, omitBy } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MultiFactorType } from '../../graphql/generated';
import type { MfaReason } from '../../lib/auth-store';
import { useAuthStore } from '../../lib/auth-store';
import { readSecurityKey } from '../../lib/security-key';

interface MultiFactorDialogProps {
  message?: string;
  reason: MfaReason;
  onConfirm({
    otp,
    form,
  }: {
    otp: Record<MultiFactorType, string>;
    form: UseFormReturnType<any>;
  }): void;
}

const mfaCopy = {
  [MultiFactorType.App]: {
    title: 'App auðkenning',
    description: 'Sláðu inn 6 stafa kóðann úr auðkenningarappinu þínu',
    button: '',
  },
  [MultiFactorType.Email]: {
    title: 'Tölvupóst auðkenning',
    description: 'Sláðu inn 8 stafa kóðann sem sendur verður á netfangið þitt',
    button: 'Fá kóða',
  },
  [MultiFactorType.Sms]: {
    title: 'SMS auðkenning',
    description:
      'Sláðu inn 6 stafa kóðann sem sendur verður í símanúmerið þitt',
    button: 'Fá kóða',
  },
  [MultiFactorType.SecurityKey]: {
    title: 'Öryggislykill',
    description:
      'Vertu með öryggislykil þinn við höndina og smelltu á hnappinn',
    button: 'Lesa öryggislykil',
  },
};

export function MultiFactorInput({
  type,
  reason,
  value,
  error,
  onChange,
  autoFocus,
}: {
  type: MultiFactorType;
  reason: MfaReason;
  autoFocus?: boolean;
  error?: React.ReactNode;
  value: string;
  onChange(value: string): void;
}) {
  const auth = useAuthStore();
  const delayRef = useRef<NodeJS.Timeout>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [sending, setSending] = useState(false);
  const [delay, setDelay] = useState(0);
  const isSendable = [MultiFactorType.Sms, MultiFactorType.Email].includes(
    type
  );

  const onSendCode = useCallback(async () => {
    if (isSendable) {
      setSending(true);
      try {
        if (await auth.actions.sendMfa(type as MultiFactorType.Sms, reason)) {
          setDelay(10);
          inputRef.current?.focus();
        }
      } catch (err) {}
      setSending(false);
    }
  }, [auth.actions, isSendable, reason, type]);

  useEffect(() => {
    if (delay > 0) {
      delayRef.current = setInterval(() => {
        setDelay(delay - 1);
      }, 1000);
    }
    return () => delayRef.current && clearInterval(delayRef.current);
  }, [delay]);

  const maxChars = type === MultiFactorType.Email ? 8 : 6;

  const copy = mfaCopy[type];

  return (
    <TextInput
      ref={inputRef}
      key={type}
      label={copy.title}
      value={value}
      description={copy.description}
      autoFocus={autoFocus}
      error={error}
      onChange={(e) =>
        onChange(
          e.currentTarget.value.replace(/\D/g, '').substring(0, maxChars)
        )
      }
      size="md"
      type="tel"
      maxLength={maxChars}
      placeholder={'*'.repeat(maxChars)}
      styles={{
        root: {
          marginBottom: 24,
        },
        label: {
          textTransform: 'none',
          fontSize: 18,
          fontWeight: 600,
          marginBottom: 8,
        },
        input: { fontWeight: 600, fontSize: 18, fontFamily: 'monospace' },
        description: {
          fontSize: 17,
          color: 'dimmed',
          marginBottom: 8,
        },
      }}
      rightSectionWidth={80}
      rightSection={
        isSendable && (
          <Button
            compact
            sx={{ width: 70, minHeight: 32 }}
            size="sm"
            variant="light"
            onClick={onSendCode}
            disabled={sending || delay > 0}>
            {sending ? (
              <Loader size="xs" color="gray" />
            ) : delay > 0 ? (
              delay.toString()
            ) : (
              copy.button
            )}
          </Button>
        )
      }
    />
  );
}

const SecurityKeyInput = ({
  value,
  onChange,
  error,
}: {
  value: string;
  error?: React.ReactNode;
  onChange(str: string): void;
}) => {
  const copy = mfaCopy.SECURITY_KEY;

  // const isRead = value && value !== '';
  return (
    <Input.Wrapper
      label={copy.title}
      description={copy.description}
      styles={{
        root: {
          marginBottom: 24,
        },
        label: {
          textTransform: 'none',
          fontSize: 18,
          fontWeight: 600,
          marginBottom: 8,
        },
        // input: { fontWeight: 600, fontSize: 18, fontFamily: 'monospace' },
        description: {
          fontSize: 17,
          color: 'dimmed',
          marginBottom: 8,
        },
      }}
      error={error}>
      <Button
        fullWidth
        variant="light"
        size="md"
        radius="md"
        onClick={() => {
          readSecurityKey(false).then((response) => onChange(response));
        }}>
        {copy.button}
      </Button>
    </Input.Wrapper>
  );
};

const QUERY = gql`
  query securityMFA {
    me {
      id
      multiFactorMethods
      securityKeyRequiresOTP
    }
  }
`;

export function MultiFactorDialog({
  onConfirm,
  message,
  reason,
  modalId,
}: any) {
  // const { onConfirm, message, reason } = innerProps;
  const modals = useModals();
  const res = useQuery(QUERY);
  const form = useForm({
    initialValues: {
      APP: '',
      EMAIL: '',
      SMS: '',
      SECURITY_KEY: '',
    },
  });

  const methods = res.data?.me?.multiFactorMethods || [];
  const securityKeyRequiresOTP = res.data?.me?.securityKeyRequiresOTP || false;
  const isSecurityKey = methods.includes(MultiFactorType.SecurityKey);
  const isApp = methods.includes(MultiFactorType.App);
  const isEmail = methods.includes(MultiFactorType.Email);
  const isSMS = methods.includes(MultiFactorType.Sms);
  const isMFA = (isSecurityKey && securityKeyRequiresOTP) || !isSecurityKey;

  const closeDialog = () => {
    modals.closeModal('mfa-modal');
  };

  const isPrepared =
    methods.filter((method: keyof typeof form.values) => {
      const value = form.values[method];
      if (method === 'SECURITY_KEY') {
        return value.length > 0;
      }
      return value.length === (method === 'EMAIL' ? 8 : 6);
    }).length === methods.length;

  const onSubmit = () => {
    const otp = omitBy(form.values, isNil) as Record<MultiFactorType, string>;
    form.clearErrors();
    return onConfirm?.({
      otp,
      form,
      closeDialog,
    });
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      {message && (
        <Text size="lg" color="dimmed">
          {message}
        </Text>
      )}
      <Divider mt="lg" sx={{ borderTopWidth: 2, opacity: 0.2 }} />
      <Skeleton mt="lg" visible={res.loading}>
        {isSecurityKey && (
          <SecurityKeyInput
            value={form.values.SECURITY_KEY}
            error={form.errors.SECURITY_KEY}
            onChange={(value) => {
              form.setFieldValue('SECURITY_KEY', value);
              if (!isMFA) {
                onSubmit();
              }
            }}
          />
        )}
        {isMFA && isEmail && (
          <MultiFactorInput
            type={MultiFactorType.Email}
            reason={reason}
            value={form.values.EMAIL}
            error={form.errors.EMAIL}
            onChange={(value) => form.setFieldValue('EMAIL', value)}
          />
        )}
        {isMFA && isSMS && (
          <MultiFactorInput
            type={MultiFactorType.Sms}
            reason={reason}
            value={form.values.SMS}
            error={form.errors.SMS}
            onChange={(value) => form.setFieldValue('SMS', value)}
          />
        )}
        {isMFA && isApp && (
          <MultiFactorInput
            type={MultiFactorType.App}
            reason={reason}
            value={form.values.APP}
            error={form.errors.APP}
            onChange={(value) => form.setFieldValue('APP', value)}
          />
        )}
      </Skeleton>
      <Center mt="lg">
        {!isPrepared ? (
          <Button
            size="lg"
            radius="md"
            type="button"
            color="gray"
            variant="light"
            onClick={() => modals.closeModal(modalId)}>
            Hætta við
          </Button>
        ) : (
          <Button size="lg" radius="md" type="submit">
            Staðfesta
          </Button>
        )}
      </Center>
    </form>
  );
}
